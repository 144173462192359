<script setup>
import Image from "~/components/atoms/Image.vue";
import Text from "~/components/atoms/Text.vue";

const props = defineProps({
  sku: String,
  alternative_products: {
    type: Object,
    required: true
  }
})

const emits = defineEmits(['hover', 'unhover'])

let timer = null

const hover = (color, image) => {
  clearTimeout(timer)
  hoveredColor.value = color

  emits('hover', image)
}

const unHover = () => {
  timer = setTimeout(() => {
    hoveredColor.value = null
    emits('unhover')

  }, 500)
}

const color = ref(props.alternative_products?.products[0]?.color)
const hoveredColor = ref(null)
const showMore = ref(false)
</script>

<template>
  <div class="g-8" v-if="alternative_products?.products?.length">
    <Text class="sign">{{ alternative_products.alternative_main_sign }}: <strong>{{ hoveredColor ?? color }}</strong>
    </Text>

    <div class="row g-8 wrap the-same-gap" style="padding-right: 40px;">
      <template v-for="(alternative_product,index) in alternative_products.products">
        <nuxt-link @mouseleave="unHover"
                   @mouseenter="alternative_product.sku !== props.sku ? hover(alternative_product.color,alternative_product.full_image): false"
                   v-if="showMore || (!showMore && index < 6)"
                   :to="'/shop/'+alternative_product.url" style="width: 55px;" class="aspect-1 hbb"
                   :class="{active:  alternative_product.sku === props.sku }">
          <Image :src="alternative_product.image"/>

          <div class="open-more flex-center" @click.stop.prevent="showMore=true"
               v-if="!showMore && index === 5 && alternative_products.products.length > 6">
            <Text tag="strong">+ {{ alternative_products.products.length - 5 }}</Text>
          </div>
        </nuxt-link>
      </template>
    </div>
  </div>
</template>

<style scoped lang="scss">
.open-more {
  position: absolute;
  bottom: 0;
  right: 0;
  top: 0;
  left: 0;
  background: rgba(52, 52, 52, .52);
  color: white;
  padding: 4px 8px;
  font-size: 18px;
}

.sign {
  color: $light-black;

  strong {
    color: $dark-black;
  }

  @include smd {
    font-size: 12px;
  }
}
</style>
