<script setup>
const config = useRuntimeConfig().public;

if (process.client) {
  const existingScript = document.querySelector('script[src="https://js.klarna.com/web-sdk/v1/klarna.js"]');
  if (!existingScript) {
    useHead({
      script: [
        {
          key: 'klarna-sdk',
          src: 'https://js.klarna.com/web-sdk/v1/klarna.js',
          async: true,
          'data-client-id': config.LANGUAGE === 'da-DK' ? 'bdcee350-6491-5f61-8e4d-bb89c864158e' : 'b575e1e3-99c6-5900-8098-5cb8b8b0e18f'
        }
      ]
    });
  }

  onMounted(() => {
    window.KlarnaOnsiteService?.push({eventName: 'refresh-placements'});
  });
}

const props = defineProps({
  totalPrice: {
    type: Number,
    required: true
  },
});
</script>

<template>
  <div class="klarna-parts">
    <!-- Placement v2 -->
    <client-only>
      <klarna-placement
          data-key="credit-promotion-badge"
          :data-locale="config.LANGUAGE"
          :data-purchase-amount="(props.totalPrice?.toFixed(2) || 0) * 100"
      ></klarna-placement>
    </client-only>
  </div>
</template>

<style scoped lang="scss">
</style>
