<template>
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.499992 0.5V11.5H11.5V4.25208L8.05 6.58542V4.25208L4.49999 6.58542V0.5H0.499992Z" :stroke="stroke"
          stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  props: {
    stroke: {type: String, default: '#18181F'}
  }
}
</script>
