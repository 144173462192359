<script setup>
import ProductCalculator from "~/components/organisms/ProductCalculator.vue";
import Breadcrumbs from "~/components/organisms/Breadcrumbs.vue";
import Heading from "~/components/atoms/Heading.vue";
import ProductPrice from "~/components/molecules/ProductPrice.vue";
import VolumePriceBlock from "~/components/molecules/VolumePriceBlock.vue";
import InfoBlock from "~/components/molecules/InfoBlock.vue";
import Text from "~/components/atoms/Text.vue";
import ProductLabels from "~/components/molecules/ProductLabels.vue";
import Image from "~/components/atoms/Image.vue";
import AlternativeProducts from "~/components/molecules/ProductPage/AlternativeProducts.vue";
import Selects from "~/components/molecules/ProductPage/Selects.vue";
import {useCurrencyStore} from "~/store/currencyStore";

const props = defineProps({
  product: {
    type: Object,
    required: true
  },
  supplementary: {
    type: Array,
    required: false
  },
  bundleToken: {
    required: false
  }
})

const currencyStore = useCurrencyStore()


const emit = defineEmits(['show-sample-form', 'cartAdded', 'selectChange', 'bundleChanged'])
const {product} = props
const currentBundle = ref(null)
const currentBundleIndex = ref(0)
const mainPrice = ref(product.min_volume_amount == 1 ? product.prices.volume : product.prices.standard)

const cartAdded = () => {
  if (props.supplementary.length) {
    currentBundle.value = props.supplementary[currentBundleIndex.value]
  }

  emit('cartAdded')
}

const bundleClosed = () => {
  currentBundleIndex.value++

  if (currentBundleIndex.value >= props.supplementary.length) {
    currentBundle.value = null
  }

  currentBundle.value = props.supplementary[currentBundleIndex.value]
}

watch(() => currentBundle.value, () => {
  emit('bundleChanged', currentBundle.value)
})

watch(() => props.bundleToken, () => {
  bundleClosed()
})

</script>

<template>
  <div class="g-8">
    <Breadcrumbs v-if="$device.isDesktop" :breadcrumbs="product.breadcrumbs.data"/>
    <div class="g-32">
      <Heading class="name">{{ product.name }}</Heading>

      <div class="g-32 the-same-gap">
        <div class="price-block row full-width g-16">
          <ProductPrice class="product" unit-margin-top="6px" unit-font-size="17px" unit-font-weight="400"
                        font-size="30px"
                        :digits="0"
                        :unit="product.unit === 'm2' ? 'm2' : null"
                        :lightUnit="true"
                        :price="mainPrice"/>
          <div class="restparti no-flex" v-if="product.status==='Restparti'">
            <Text>({{ Math.ceil(product.prices.volume / product.restparti.qty) }} {{ currencyStore.currency }})</Text>
          </div>
          <template v-if="mainPrice < product.prices.recommended">
            <ProductPrice font-size="14px" type="old" :unit="product.unit" class="product" :digits="0" :light="true"
                          :line="true"
                          :price="product.prices.recommended"/>
            <Text font-size="16px" font-weight="600" class="discount" type="red">-{{ product.prices.discount }}%</Text>
          </template>

          <ProductLabels :right="true" style="right: 0" :labels="product.labels"/>
        </div>

        <VolumePriceBlock v-if="product.min_volume_amount !== 1 && !['Restparti','Offertvara'].includes(product.status)"
                          :price="product.prices.volume"
                          :min-volume-qty="product.min_volume_qty"
                          :unit="product.unit"/>

        <AlternativeProducts v-if="product.alternative_products?.products?.length > 1" :sku="product.sku"
                             @hover="$emit('alternativeHover', $event)"
                             @unhover="$emit('alternativeUnhover')"
                             :alternative_products="product.alternative_products"/>

        <Selects @change="$emit('selectChange',$event)" :sku="product.sku" :selects="product.selects"
                 :collection="product.collection"/>

        <div class="g-16" v-if="product.status_descriptions.length">
          <InfoBlock :color="status_description.color"
                     :background="status_description.background"
                     :name="status_description.label"
                     :text="status_description.text"
                     v-for="status_description in product.status_descriptions"/>
        </div>

        <ProductCalculator :unit_per_box="product.unit_per_box"
                           @cartAdded="cartAdded"
                           :sample_is_available="product.sample_is_available"
                           :min_volume_to_buy="product.min_volume_to_buy"
                           @show-sample-form="$emit('show-sample-form', true)"
                           :id="product.id"
                           :restparti="product.restparti"
                           :cart_multiplicity="product.cart_multiplicity"
                           :max_cart_qty="product.max_cart_qty"
                           :url="product.url"
                           :sample_modal_is_available="product.sample_modal_is_available"
                           :image="product.files.frontal_images.length ? product.files.frontal_images[0]?.url : product.files.previews[0]?.url"
                           :name="product.name"
                           :sku="product.sku"
                           :exists_in_showroom="product.exists_in_showroom"
                           :price-per-box="product.prices.pricePerBox"
                           :standard_price="product.prices.standard"
                           :niche_id="product.niche_id"
                           :status="product.status"
                           :volume_price="product.prices.volume"
                           :sample_price="product.sample_price"
                           :sample_delivery_time="product.sample_delivery_time"
                           :min_volume_qty="product.min_volume_qty"
                           :supplier_available_date="product.supplier_available_date"
                           :supplier_stock="product.supplier_stock"
                           :supplementary="supplementary"
                           :delivery_time_html="product.delivery_time_html" :unit="product.unit"/>

        <div class="mini-banner g-8" v-if="product.mini_banner">
          <nuxt-link :to="product.mini_banner.url" class="full-width" style="aspect-ratio: 1/0.3">
            <Image loading="lazy" :src="product.mini_banner.image"/>
          </nuxt-link>

          <Text font-size="12px" v-if="product.mini_banner.text">{{ product.mini_banner.text }}</Text>
        </div>

        <hr>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.restparti {
  color: #3c78d8;
  font-size: 20px;
  font-weight: 600;
}

.g-32:deep(h1) {
  @include smd {
    font-size: 20px;
    line-height: 27px;
  }
}

.name {
  line-height: 40px;
}

.discount {
  @include smd {
    font-size: 10px !important;
  }
}
</style>
