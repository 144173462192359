<template>
  <div class="shop-the-look row full-width column-mobile">
    <Modal no-heading v-if="showImage" @close="showImage=false" :width="740" :height="740" extraClass="medium-square">
      <NuxtImg style="scale: 1.06;" :src="image"/>
    </Modal>
    <div class="preview no-flex" @click="showImage=true">
      <img class="shop-the-look-image"
           :src="preview">
      <div class="swiper-btn white">
        <img src="/img/web-icons/pl.png" alt="" class="white">
      </div>
      <h2 class="over-title">
        <div class="title">Shop</div>
        <div class="title">the look</div>
      </h2>
    </div>
    <div class="stlc">
      <Carousel :infinity="false" :items="products" :items-to-show="2.6" :items-to-show-tablet="5"
                :items-to-show-mobile="2.5"
                :items-to-scroll="1">
        <template #default="{item}">
          <nuxt-link :to="item.publish ? '/shop/'+item.url : false" class="shop-the-look-product" style="gap: 5px">
            <img class="shop-the-look-product-image" :src="item.image" alt=""/>

            <div class="g-16">
              <Heading v-html="item.bold_name" font-weight="400" tag="h3" class="product-name"/>
              <div class="g-8" v-if="item.publish">
                <div class="row full-width" style="gap: 5px; justify-content: center;">
                  <ProductPrice class="price" :price="item.hc_standard_price" :digits="0"/>
                  <ProductPrice class="price" type="old" :price="item.hc_recommended_price" :digits="0"/>
                </div>
                <Button type="bordered" class="btn"
                        @click.stop.prevent="store.addItemToCart({id:item.id,count:1,source:store.sources.product_page_shop_the_look})">
                  <MarketCartIcon width="16px"/>
                </Button>
              </div>
            </div>
          </nuxt-link>
        </template>
      </Carousel>
    </div>
  </div>
</template>

<script>
import Carousel from "~/components/molecules/Carousel.vue";
import Heading from "~/components/atoms/Heading.vue";
import ProductPrice from "~/components/molecules/ProductPrice.vue";
import Modal from "~/components/molecules/Modal.vue";
import Button from "~/components/atoms/Button.vue";
import CartIcon from "~/components/icons/CartIcon.vue";
import MarketCartIcon from "~/components/icons/MarketCartIcon.vue";
import {useCartStore} from "~/store/cartStore.js";

export default {
  components: {MarketCartIcon, CartIcon, Button, ProductPrice, Heading, Carousel, Modal},
  props: ['preview', 'image', "products"],
  data() {
    return {
      showImage: false,
      store: useCartStore()
    }
  },
}
</script>

<style scoped lang="scss">
.btn {
  border: .5px solid #d7d7d7 !important;
  border-radius: 5px;
  width: 205px !important;
  height: 37px !important;
  background: #f9f9f9 !important;

  @include smd {
    width: 124px !important;
    height: 27px !important;
  }

  &:hover {
    background: #fff !important;
  }
}

.product-name {
  font-size: 13px;
  height: 39px !important;

  @include smd {
    height: auto !important;
  }
}

.price {
  font-size: 14px;
  font-weight: 600 !important;
}

.shop-the-look {
  padding-left: 80px;
  display: flex;
  gap: 60px;

  @include smd {
    padding-left: 0;
    //transform: scale(1.05);
  }
}

.shop-the-look-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.preview {
  width: 30.5%;
  min-width: 30.5%;
  filter: drop-shadow(0 32px 48px rgba(82, 82, 82, .25));
  position: relative;
  cursor: pointer;
  background-size: cover !important;
  background-position: 50% !important;

  @include smd {
    width: 100%;
    min-width: 100%;
    height: 483px;
  }

  &:hover {
    img {
      filter: brightness(.75);
    }
  }
}

.swiper-btn {
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 32px 48px rgba(82, 82, 82, .08);
  position: absolute;
  cursor: pointer;
  z-index: 9;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  opacity: 0;
  width: 71px;
  height: 71px;
  transition: opacity .2s ease-in-out;
  background: hsla(0, 0%, 100%, .9);
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  margin-top: 0;

  img {
    width: 25px;
    height: 25px;
  }
}

.preview:hover .swiper-btn {
  opacity: 1;
  transition-delay: .1s;
}

.over-title {
  position: absolute;
  bottom: 50%;
  transform: translateY(50%);

  @include smd {
    bottom: 23%;
    left: 15%;
  }

  .title {
    padding: 0 5px;
    margin-left: -40px;
    background: $black;
    color: $white;
    font-weight: 600;
    font-size: 32px;
    width: fit-content;
    margin-top: -5px;
  }
}

.stlc {
  position: relative;
  display: block;
  overflow: hidden;

  @include smd {
    width: 100%;
    max-width: 100%;
    margin-top: -100px;
  }
}

.custom-modal {
  width: 400px;
  height: 400px;
}

.shop-the-look-product {
  max-width: 350px;

  @include smd {
    width: 140px;
  }

  .shop-the-look-product-image {
    max-width: 350px;
    margin: auto;

    @include smd {
      width: 140px;
    }
  }

  h3 {
    @include smd {
      font-size: 11px;
    }
  }

  .price {
    @include smd {
      font-size: 13px;
    }
  }

  button {
    width: 205px;
    margin: auto;

    @include smd {
      width: 120px;
    }

    svg {
      @include smd {
        width: 16px;
        height: 16px;
      }
    }
  }
}
</style>
