<script setup lang="ts">
import Image from "~/components/atoms/Image.vue";
import Text from "~/components/atoms/Text.vue";
import ProductPrice from "~/components/molecules/ProductPrice.vue";
import Button from "~/components/atoms/Button.vue";
import AlternativeCartIcon from "~/components/icons/AlternativeCartIcon.vue";
import PlusIcon from "~/components/icons/PlusIcon.vue";
import {useCartStore} from "~/store/cartStore";

const store = useCartStore()
const props = defineProps({
  product: {
    type: Object,
    required: true
  }
})

const emits = defineEmits(['click'])

const handleClick = (e: Event) => {
  if (props.product.type === 'supplementary_bundle') {
    e.preventDefault()
    emits('click', props.product)
  }
}

const component = computed(() => {
  if (props.product.type !== 'supplementary_bundle' && props.product.url) return defineNuxtLink({})
  return 'div'
})
</script>

<template>
  <component :is="component" @click="handleClick"
             :to="'/shop/'+props.product.url+'/'"
             class="product full-width" :class="{clickable:props.product.url}">
    <div class="g-4">
      <div class="image full-width aspect-1">
        <Image :src="props.product.image"/>
      </div>

      <Text tag="strong" v-if="props.product.type === 'supplementary_bundle'" class="title suppl-bundle">{{
          props.product.name
        }}
      </Text>
      <p v-else class="title" v-html="props.product.bold_name"/>

      <Text class="description" v-if="props.product.type === 'supplementary_bundle' && props.product.description">
        {{ props.product.description }}
      </Text>

      <div class="row flex-center full-width g-4">
        <template v-if="props.product.type === 'supplementary_bundle'">
          <Text tag="strong">{{ $t('product.from_short') }}</Text>
          <ProductPrice :digits="0" :price="props.product.price"/>
        </template>
        <template v-else-if="props.product.hc_volume_price && props.product.url">
          <ProductPrice :digits="0" :price="props.product.hc_volume_price"/>
          <ProductPrice :digits="0" :price="props.product.hc_standard_price" type="old"/>
        </template>
      </div>

      <div class="bundle-count row" v-if="props.product.type === 'bundle'">
        <b>{{ props.product.qty }} st</b>
      </div>
      <Button v-else-if="props.product.type === 'supplementary_bundle'" class="buy" type="bordered">
        <PlusIcon stroke="black" width="16" height="16"/>
      </Button>
      <Button v-else class="buy"
              @click.stop.prevent="store.addItemToCart({id: product.id, count: 1,source:store.sources.better_together_combo})"
              type="bordered">
        <AlternativeCartIcon width="16" height="16"/>
      </Button>
    </div>
  </component>
</template>

<style scoped lang="scss">
.product {
  background: $white;
  width: 160px;
  min-height: 318px;
  max-height: 318px;
  padding-bottom: 10px;
  display: flex;
  height: 333px;

  @include smd {
    margin-right: 10px;
  }

  .title {
    font-size: 11px;
    height: fit-content;

    &.suppl-bundle {
      font-size: 14px;
    }
  }

  .image {
    max-width: 160px;
    max-height: 160px;
  }

  .price {
    font-size: 13px;
  }

  .description {
    font-size: 12px;
    min-height: 36px;
  }

  .buy {
    width: 85%;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    border: .5px solid #d7d7d7;
    border-radius: 5px;

    &:deep(svg) {
      stroke-width: 1.5px;
    }
  }

  .bundle-count {
    text-align: center;
    font-size: 13px;
    transform: translateY(10px);
    margin: auto;

    b {
      min-width: 40px;
      height: 21px;
      background-color: #ff5959;
      color: $white;
    }
  }
}
</style>
